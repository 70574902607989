import React from "react";
import Dashboard from "./Dashboard";
import InAdDashboard from "../InfuAndAdv/User/InAdDashboard";

const InfuAndAdv = () => {
  return (
    <div>
      {/* <Dashboard /> */}
      <InAdDashboard />
    </div>
  );
};

export default InfuAndAdv;
