import React from "react";
import PostLead from "./PostLead";


const Lead = () => {
  return (
    <div className="min-h-screen  flex flex-col ">
      <PostLead />
    </div>
  );
};

export default Lead;
