import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseUrl } from "../../../utils/const";

const AcceptedDarbarStatus = () => {
  const [darbars, setDarbars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatingId, setUpdatingId] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState(null); // State for captured photo
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const fetchDarbars = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(`${baseUrl}getUserAcceptedDarbars`, {
          headers: {
            Authorization: token,
          },
        });
        setDarbars(response.data.userDarbars);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDarbars();
  }, []);

  const handleUpdateClick = (darbar) => {
    setUpdatingId(darbar._id); // Set the darbar ID for updating
  };

  const openCamera = () => {
    setIsCameraOpen(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error("Error accessing camera:", err);
      });
  };

  const capturePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, 300, 200); // Draw the video frame on the canvas
    canvasRef.current.toBlob((blob) => {
      setPhoto(blob); // Save the photo as a blob
      setIsCameraOpen(false); // Close camera after capturing
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop()); // Stop the camera stream
    });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    setUpdateError(null);

    try {
      const token = localStorage.getItem("userToken");

      const formData = new FormData();
      formData.append("darbarId", updatingId);
      formData.append("videoUrl", videoUrl);
      formData.append("note", note);
      if (photo) {
        formData.append("photo", photo, "photo.jpg"); // Attach captured photo
      }

      await axios.put(
        `${baseUrl}updateDarbarStatusReporter`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setVideoUrl("");
      setNote("");
      setPhoto(null);
      setUpdatingId(null);
    } catch (err) {
      setUpdateError(err.message);
    } finally {
      setUpdateLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Accepted Darbars</h1>
      {darbars.length === 0 ? (
        <p>No accepted Darbars found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {darbars.map((darbar) => (
            <div
              key={darbar._id}
              className="border rounded-lg p-4 shadow-md bg-white"
            >
              <h2 className="font-semibold">
                Darbar ID: {darbar.darbarId._id}
              </h2>
              <p><strong>Darbar By:</strong> {darbar.darbarId.darbarBy}</p>
              <p><strong>Date:</strong> {new Date(darbar.darbarId.darbarDate).toLocaleDateString()}</p>
              <p><strong>State:</strong> {darbar.darbarId.darbarState}</p>
              <p><strong>City:</strong> {darbar.darbarId.darbarCity}</p>
              <p><strong>Area:</strong> {darbar.darbarId.area}</p>
              <p><strong>Village:</strong> {darbar.darbarId.village}</p>
              <p><strong>Purpose:</strong> {darbar.darbarId.purpose}</p>
              <p><strong>Reason:</strong> {darbar.darbarId.darbarReason}</p>
              <p><strong>Pincode:</strong> {darbar.darbarId.pincode}</p>
              <p><strong>People Available:</strong> {darbar.darbarId.peopleAvailable}</p>
              <p><strong>People Required:</strong> {darbar.darbarId.peopleRequired}</p>
              <p><strong>Type:</strong> {darbar.darbarId.darbarType}</p>
              <p><strong>Status:</strong> {darbar.darbarId.darbarStatus}</p>
              <p><strong>Accepted Date:</strong> {new Date(darbar.acceptedDate).toLocaleString()}</p>
              <p><strong>Admin Status:</strong> {darbar.statusByAdmin}</p>
              <button
                onClick={() => handleUpdateClick(darbar)}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Update
              </button>
            </div>
          ))}
        </div>
      )}

      {updatingId && (
        <div className="mt-4 p-4 border rounded-lg bg-gray-100">
          <h2 className="text-lg font-bold mb-2">Update Darbar Status</h2>
          {updateError && <p className="text-red-500">{updateError}</p>}
          <form onSubmit={handleUpdateSubmit}>
            <div className="mb-2">
              <label className="block mb-1">Video URL</label>
              <input
                type="text"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                // required
                className="border rounded-lg px-3 py-2 w-full"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-1">Note</label>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                // required
                className="border rounded-lg px-3 py-2 w-full"
                rows="3"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-1">Capture Photo</label>
              {isCameraOpen ? (
                <div>
                  <video ref={videoRef} className="w-full h-48 bg-black"></video>
                  <button
                    type="button"
                    onClick={capturePhoto}
                    className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Capture
                  </button>
                  <canvas ref={canvasRef} width="300" height="200" className="hidden"></canvas>
                </div>
              ) : (
                <button
                  type="button"
                  onClick={openCamera}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Open Camera
                </button>
              )}
              {photo && <p>Photo captured successfully!</p>}
            </div>
            <button
              type="submit"
              className={`bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ${updateLoading ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={updateLoading}
            >
              {updateLoading ? "Updating..." : "Update"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AcceptedDarbarStatus;
